//if (!/localhost/.test(window.location.href)) console = {log: function(){}, dir: function(){}, info: function(){}, error: function(){}};

(function () {

    var debug = false

    if (debug === false) {
        if ( typeof(window.console) === 'undefined') { window.console = {}; }
        window.console.log = function () {};
    }

})()

function getCookie(c_name){
	var c_value = document.cookie;
	var c_start = c_value.indexOf(" " + c_name + "=");
	if (c_start == -1){
		c_start = c_value.indexOf(c_name + "=");
	}
	if (c_start == -1){
		c_value = null;
	}else{
		c_start = c_value.indexOf("=", c_start) + 1;
		var c_end = c_value.indexOf(";", c_start);
		if (c_end == -1){
			c_end = c_value.length;
		}
		c_value = unescape(c_value.substring(c_start,c_end));
	}
	return c_value;
}

function setCookie(c_name,value,exdays){
	var exdate=new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var c_value=escape(value) + ((exdays===null) ? "" : "; expires="+exdate.toUTCString());
	document.cookie=c_name + "=" + c_value;
}


//console.log("init");
//console.log($("body").data("lang"));
let app;
let pageSettings = {
	lang: $("body").data("lang"),
	prevPage: "",
	currentPage: "",
	nextPage: ""
};
const categories = {
	"es": {
		"#audiovisual": "#audiovisual",
		"#contents": "#contenidos",
		"#digital_area": "#area-digital",
		"#copyright_management": "#gestion-derechos",
		"#innovation": "#innovacion"
	},
	"ca": {
		"#audiovisual": "#audiovisual",
		"#contents": "#continguts",
		"#digital_area": "#area-digital",
		"#copyright_management": "#gestio-drets",
		"#innovation": "#innovacio"
	},
	"en": {
		"#audiovisual": "#audiovisual",
		"#contents": "#contents",
		"#digital_area": "#digital-area",
		"#copyright_management": "#rights-management",
		"#innovation": "#innovation"
	}
}
let siteSettings = {};
let hrefLang = {};
const isMobile = window.innerWidth < 768;
const isDesktop = window.innerWidth >= 1024;
const isTablet = !isMobile && !isDesktop;

const NUM_OF_SECTIONS = 5;
const host = window.location.origin;


const bindGeneralEvents = function() {
	$(".top-menu-mobile__open").on("click", function(e){
		e.stopPropagation();
		e.preventDefault();
		console.log("open menu",e);
		$(".menu-mobile").fadeIn();
	});

	$(".menu-mobile__close").on("click", function(e){
		e.stopPropagation();
		e.preventDefault();
		console.log("close menu",e);
		$(".menu-mobile").fadeOut();
		return false;
	});

	$(".top-menu-desktop__open").on("click", function(e){
		e.stopPropagation();
		e.preventDefault();
		console.log("open menu",e);
		$(".home__head__sections").fadeOut();
		$(".section-menu").fadeOut();
		$(".top-menu-desktop").fadeOut();
		$("body").css("overflow-y", "hidden");
		$(".go-top").css("visibility", "hidden");
		$(".menu-desktop").fadeIn();
	});

	$(".menu-desktop__close").on("click", function(e){
		e.stopPropagation();
		e.preventDefault();
		console.log("close menu",e);
		$(".top-menu-desktop").fadeIn();
		$(".home__head__sections").fadeIn();
		$(".section-menu").fadeIn();
		$(".menu-desktop").fadeOut();
		$("body").css("overflow-y", "auto");
		$(".go-top").css("visibility", "visible");
		return false;
	});

	$('.home__head__sections').on('inview', function(event, isInView) {
		if (isInView) {
			$(".go-top").fadeOut();
		} else {
			$(".go-top").fadeIn();
		}
	});

	$('.head_arrow_down').on('inview', function(event, isInView) {
		if (pageSettings.currentPage !== "index") {
			if (isInView) {
				$(".go-top").fadeOut();
			} else {
				$(".go-top").fadeIn();
			}
		}
	});
	
	// Dialog video player
	$(".video__modal").on("click", function(e){
		e.preventDefault();
		document.querySelector('#'+e.target.dataset.ref).showModal();
	});
	$('.close__dialog--videoplayer').on("click", function(e) {
		e.preventDefault();
		document.querySelector('#'+e.target.closest('dialog').id).close();
	});

	// Open video corporate
	$(".reproductor").on("click", function(e){
		e.preventDefault();
		console.log("play home video");

		if($(this).attr('data-offset')){
			console.log($(".modal-player-home").offset().top);
			$('.modal-player-home').attr('data-offset',$(".modal-player-home").offset().top);
		}

		$('.modal-player-home__content video').attr('src', $(this).attr('data-video'));
		$("body").css("overflow-y","hidden");
	    $(".modal-player-home iframe").show();
	    $(".modal-player-home").fadeIn();
	    $('#video_player video')[0].play();
	});
	
	// Close video corporate
	$(".modal-player-home__close").on("click", function(){
	    console.log('stop');
	    $("body").css("overflow-y","auto");
	    $(".modal-player-home").fadeOut();
	    $('.modal-player-home__content video').attr('src', '');
	    
	    if($('.modal-player-home').attr('data-offset')!=""){
	    	console.log($('.modal-player-home').attr('data-offset'));
	    	$("body").scrollTo({left:0, top:$('.modal-player-home').attr('data-offset')+"px"},800);
	    };

	    document.addEventListener("DOMContentLoaded", function(event) {
	      var audio = document.getElementsByTagName("audio")[0];
	      audio.pause();
	    });
	});


	$(".documento").on("click", function(e){
		window.open('/static/pdf/OB_Van_1802.pdf');
	});	

	$(".documento2").on("click", function(e){
		window.open('/static/pdf/2023_MEDIAPRO_NORTH_AMERICA_MOBILE_UNITS.pdf');
	});	

	if (isMobile) {
		$("body").on("click", ".go-top", function(){
			console.log("click to top", pageSettings.currentPage)
			if (pageSettings.currentPage === "index") {
				appHome.swiperV.slideTo(0,500);
			} else {
				$("body").scrollTo({left:0,top:0}, 500);
			};
		});
	} else {
		$("body").on("click", ".go-top", function(){
			console.log("click to top", pageSettings.currentPage)
			$.scrollTo({left:0,top:0}, 500);
		});
	}

}

//manejo de pop up de cookies

//productos
const products = {
	'storage':0,
	'share':0,
	'personalization':0,
	'tracking':0
};

//close popup modal
var closeCookiesModal = function(){
	$('.overlay-div').fadeOut('fast');
	$('.modal-cookies').fadeOut('fast');
}


$('.overlay-div').on('click', function(){
	closeCookiesModal();
});

$('.close-btn').on('click', function(){
	closeCookiesModal();
});

//acordeon de textos de pop up
$('.titleCt').on('click', function(){
	if( !$(this).find('.cookie-content').hasClass('active') ) {
		$(this).find('.cookie-content').addClass('active');
		$(".btnsC").find('.rechazar').addClass('btn-desplegable');
		$(".btnsC").find('.aceptar').addClass('btn-desplegable');
		$(this).find('.cookie-content').stop(true).slideDown('100');
		$(this).find('.plusC').text('-');
	} else {
		$(this).find('.cookie-content').removeClass('active');
		$(".btnsC").find('.rechazar').removeClass('btn-desplegable');
		$(".btnsC").find('.aceptar').removeClass('btn-desplegable');
		$(this).find('.cookie-content').stop(true).slideUp('100');
		$(this).find('.plusC').text('+');
	}
});

//logica botones

$('.btnsC').find('.btn-normal').on('click', function(){
	var cookieType = $(this).data('type-cookie');
	var cookieAction = $(this).data('action');
	btnLogic(cookieType, cookieAction);
});

//funcion que se encarga del comportamiento de los botones del body
var btnLogic = function(cookieType, cookieAction){

	//storage
	if( cookieType == 'storage' && cookieAction == 1 ){
		$('.storage').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.storage').find('.btnsC').children('.aceptar').addClass('btn-active');
	}
	if( cookieType == 'storage' && cookieAction == 0 ){
		$('.storage').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.storage').find('.btnsC').children('.rechazar').addClass('btn-active');
	}
	//share
	if( cookieType == 'share' && cookieAction == 1 ){
		$('.share').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.share').find('.btnsC').children('.aceptar').addClass('btn-active');
	}
	if( cookieType == 'share' && cookieAction == 0 ){
		$('.share').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.share').find('.btnsC').children('.rechazar').addClass('btn-active');
	}
	//personalization
	if( cookieType == 'personalization' && cookieAction == 1 ){
		$('.personalization').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.personalization').find('.btnsC').children('.aceptar').addClass('btn-active');
	}
	if( cookieType == 'personalization' && cookieAction == 0 ){
		$('.personalization').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.personalization').find('.btnsC').children('.rechazar').addClass('btn-active');
	}
	//tracking
	if( cookieType == 'tracking' && cookieAction == 1 ){
		$('.tracking').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.tracking').find('.btnsC').children('.aceptar').addClass('btn-active');
	}
	if( cookieType == 'tracking' && cookieAction == 0 ){
		$('.tracking').find('.btnsC').children('.btn-normal').removeClass('btn-active');
		$('.tracking').find('.btnsC').children('.rechazar').addClass('btn-active');
	}	
};

function isYoutubeCookiesEnabled () {
	return localStorage.getItem('tracking') == 1;
}

function handleYoutubeCookies (action) {
	if (window.player) {
		let $iframe = $(window.player.getIframe()),
			newSrc;

		switch (action) {
			case 'aceptar':
				if ($iframe.attr('src').includes('youtube-nocookie')) {
					newSrc = $iframe.attr('src').replace('youtube-nocookie', 'youtube');
				}
				break;
			case 'rechazar':
				if (!$iframe.attr('src').includes('youtube-nocookie')) {
					newSrc = $iframe.attr('src').replace('youtube', 'youtube-nocookie');
				}
				break;
		}

		// Solo recargar el vídeo se la url ha sido definida
		if (newSrc) {
			$iframe.attr('src', newSrc);
			// si hay un video en curso lo recarga (cambiandole la el dominio del iframe y con el tiempo de reproducción del estado anterior)
			if (player.getVideoData()['video_id']) {
				let videoId = player.getVideoData()['video_id'],
					startSeconds = player.getCurrentTime();
				setTimeout(() => {
					player.loadVideoById({ videoId, startSeconds});
				}, 400)
			}
		}

	}
}

//borrar cookie por nombre
function delete_cookie(name) {
	document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

//acceptar todo
var acceptAll = function(){
	Object.keys(products).forEach(function(product){
		localStorage.setItem( product, 1);
	});
	localStorage.setItem('mediapro-cookies', true);
	$('.btnsC').find('.rechazar').removeClass('btn-active');
	$('.btnsC').find('.aceptar').addClass('btn-active');

	handleYoutubeCookies ('aceptar');

	setTimeout(function(){
		closePopUp();
		$(".cookies-alert").hide();
	}, 200);
}
//rechazar todo
var rejectAll = function(){
	Object.keys(products).forEach(function(product){
		localStorage.setItem( product, 0);
	});
	localStorage.setItem('mediapro-cookies', true);
	$('.btnsC').find('.aceptar').removeClass('btn-active');
	$('.btnsC').find('.rechazar').addClass('btn-active');

	delete_cookie('_ga_61VWXPZHM0');
	document.cookie = '_ga_61VWXPZHM0=; Path=/; Domain=.mediapro.tv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	document.cookie = '_ga=; Path=/; Domain=.mediapro.tv; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	delete_cookie('_gid');
	delete_cookie('_ga');
	delete_cookie('_gat');

	handleYoutubeCookies('rechazar');

	setTimeout(function(){
		closePopUp();
		$(".cookies-alert").hide();
	}, 200);
}

//muestra el boton guardar o lo oculta

//cambia los valores del objeto products dependiendo de la seleccion para luego poder guardar
var popUpBtnLogic = function(btnAction, btnData){


	var buttons = document.getElementById("wrapper").querySelectorAll(".btn-active"); 
	if(buttons.length == 4){
		$('.saveCookiesModal').removeClass('btn-disabled');
		$('.saveCookiesModal').addClass('btn-active');
		$('.saveCookiesModal').removeAttr('disabled');
	}


	$('.accept-reject').hide();
	$('.saveCookiesModal').show();
	//localStorage.setItem( btnData, btnAction);
	Object.keys(products).forEach(function(product){
		if(product == btnData){
			products[product] = btnAction;
			console.log(product + ' ' + products[product]);
		}
	});

}

//funcion para guardar lo seleccionado por el usuario
var saveProducts = function(){
	Object.keys(products).forEach(function(product){
		localStorage.setItem( product, products[product] );
	});

	isYoutubeCookiesEnabled() ? handleYoutubeCookies('aceptar') : handleYoutubeCookies('rechazar');

	localStorage.setItem('mediapro-cookies', true);
	closePopUp();
	$(".cookies-alert").hide();
};

//funcion para cerrar popup de cookies
var closePopUp = function(){
	$('.overlay-div').fadeOut();
	$('.modal-cookies').fadeOut();
}

//pasa info a la funcion popUpBtnLogic cuando se hace clic en los botones
$('.btnsC').find('.btn-normal').on('click', function(){
	
	var btnAction = $(this).data('action');
	var btnData =  $(this).data('type-cookie');

	popUpBtnLogic(btnAction, btnData);
});

//logica de botones activos
//aceptados
if( localStorage.getItem( 'share' ) == 1 ){
	$('.share').find('.aceptar').addClass('btn-active');
}
if( localStorage.getItem( 'storage' ) == 1 ){
	$('.storage').find('.aceptar').addClass('btn-active');
}
if( localStorage.getItem( 'tracking' ) == 1 ){
	$('.tracking').find('.aceptar').addClass('btn-active');
}
if( localStorage.getItem( 'personalization' ) == 1 ){
	$('.personalization').find('.aceptar').addClass('btn-active'); 
}

//rechazados
if( localStorage.getItem( 'share' ) == 0 ){
	$('.share').find('.rechazar').addClass('btn-active');
}
if( localStorage.getItem( 'storage' ) == 0 ){
	$('.storage').find('.rechazar').addClass('btn-active');
}
if( localStorage.getItem( 'tracking' ) == 0 ){
	$('.tracking').find('.rechazar').addClass('btn-active');
	delete_cookie('_ga_61VWXPZHM0'); 
	delete_cookie('_gid');
	delete_cookie('_ga');
	delete_cookie('_gat');
}
if( localStorage.getItem( 'personalization' ) == 0 ){
	$('.personalization').find('.rechazar').addClass('btn-active');
}

//logica boton guardar
// var buttons = document.getElementById("wrapper").querySelectorAll(".btn-active"); 
// if(buttons.length == 4){
// 	$('.saveCookiesModal').removeClass('btn-disabled');
// 	$('.saveCookiesModal').addClass('btn-active');
// 	$('.saveCookiesModal').removeAttr('disabled');
// }

//muestra popup
var openPopUpCookies = function(){
	$('.overlay-div').show();
	$('.modal-cookies').show();
}


//HOME
let appHome = {

	swiperV: null,
	swiperH: null,
	vPagination: null,

	map: {
		"home": 0,
		"audiovisual": 1,
		"contents": 2,
		"digital_area": 3,
		"copyright_management": 4,
		"innovation": 5,
		"info": 6
	},

	H_SWIPER_LAUNCHER_INDEX: 1, //second slide of vertical swiper is launcher of horizontal slider

	onReady: () => {
		console.log("on appHome ready", pageSettings.lang);
		appHome.initSwiper();
		appHome.bindEvents();
		bindGeneralEvents();
		appHome.onLoad();
	},

	onLoad: () => {
		
		$(".swiper-wrapper").css("opacity","1");
		console.log("on appHome load", pageSettings.lang);
		if (!isMobile) {
			$("body").scrollTo({left:0,top:0}, 500);
		}
		$(".loading").velocity({opacity:1},2000).velocity("transition.fadeOut",{
			duration:500,
			complete: function(){
				$(".loading").remove();
				if (isMobile) {
					appHome.swiperV.removeSlide(0);
					appHome.vPagination.show();
					appHome.swiperV.unlockSwipeToNext();
				}
				$(".header").velocity("transition.fadeIn",{duration: 1500});
			}
		});
	},

	bindEvents: () => {
		if (isMobile) {
			
			$(".next-slide").on("click", function(){
				appHome.swiperH[1].slideTo(1,300);
			});

			$(".more-info").on("click", function(e){
				e.preventDefault();
				const swiperIndex = $(this).data("swiper")*1;
				appHome.swiperH[swiperIndex].slideTo(1);
			});

		}

		$(".head_arrow_down").on("click", function(e){
			e.preventDefault();
			if (isMobile) {
				appHome.swiperV.slideNext();
			} else {
				$("body").scrollTo({left:0,top:$(window).height()}, 500);
			}
		});

	},

	initSwiper: () => {
		if (isMobile) {
			appHome.vPagination = $('.swiper-pagination-v');


			function onHSlideChangeStart(swiper) {
				if (swiper.activeIndex===1) {
					appHome.vPagination.hide();
				} else {
					appHome.vPagination.show();
				}
			}

			function onHSlideChangeEnd(swiper) {
				if (swiper.activeIndex===appHome.H_SWIPER_LAUNCHER_INDEX) {
					console.log(siteSettings.pages.contents_channels);
					if (appHome.swiperV.activeIndex==appHome.map.audiovisual) {
						window.location.href=siteSettings.pages.audiovisual_production_centers.url;
					}
					else if (appHome.swiperV.activeIndex===appHome.map.contents) {
						window.location.href=siteSettings.pages.contents_cinema.url;
					}
					else if (appHome.swiperV.activeIndex===appHome.map.digital_area) {
						window.location.href=siteSettings.pages.digital_area_digital_innovation.url;
					}
					else if (appHome.swiperV.activeIndex===appHome.map.copyright_management) {
						window.location.href=siteSettings.pages.copyright_management_sports.url;
					}
					else if (appHome.swiperV.activeIndex===appHome.map.innovation) {
						window.location.href=siteSettings.pages.innovation_sports_intelligence.url;
					}
				}
			}


			function onVSlideChangeEnd(swiper){
				console.log("onVSlideChangeEnd",swiper.activeIndex);
				if (swiper.activeIndex <= NUM_OF_SECTIONS) {
					$(".top-menu-mobile").fadeIn();
					$(".go-top").fadeOut();
					if (swiper.params.freeMode) {
						swiper.params.freeMode = false;
						$(".swiper-pagination-v").css("opacity",1);
						swiper.slideTo(swiper.activeIndex);
					}

				} else {
					if (!swiper.params.freeMode) {
						swiper.params.freeMode = true;
						$(".swiper-pagination-v").css("opacity",.3);
					}
					$(".top-menu-mobile").fadeOut();
					$(".go-top").fadeIn();
				}
			}


			appHome.swiperH = new Swiper('.swiper-container-h.section', {
				pagination: '.swiper-pagination-h.section',
				paginationClickable: true,
				spaceBetween: 0,
				nested: true,
				onSlideChangeStart: onHSlideChangeStart,
				onSlideChangeEnd: onHSlideChangeEnd
			});


			appHome.swiperV = new Swiper('.swiper-container-v', {
				autoHeight: false,
				direction: 'vertical',
				freeMode: false,
				freeModeMomentumRatio: 1,
				freeModeMomentumVelocityRatio: 1,
				freeModeMomentumBounce: false,
				hashnav: true,
				nested: false,
				onSlideChangeEnd: onVSlideChangeEnd,
				pagination: '.swiper-pagination-v',
				paginationClickable: true,
				spaceBetween: 0,
				paginationBulletRender: function (swiper, index, className) {
					if (index>=NUM_OF_SECTIONS+1) className += ' hidden';
					return '<span class="' + className + '"></span>';
				}
			});

			appHome.swiperV.lockSwipeToNext()
			appHome.vPagination.hide();

		}

		const swiperTop = isMobile ? '.swiper-container-h.head-mobile' : '.swiper-container-h.head';
		const swiperTopPagination = isMobile ? '.swiper-pagination-h.head-mobile' : '.swiper-pagination-h.head';
		appHome.swiperHTop = new Swiper(swiperTop, {
			pagination: swiperTopPagination,
			paginationClickable: true,
			spaceBetween: 0,
			nested: true,
			autoplay: 5000,
			loop: false
		});

	}

};


//SECTIONS
let appSubcategory = {
	swiperH: null,
	canGoBack: true,

	onReady: () => {
		console.log("on appSubcategory ready", pageSettings.currentPage);

		appSubcategory.bindEvents();
		bindGeneralEvents();

		//check loading of cover picture to use as onload

		let coverURL = isMobile ? `${host}/static/img/${pageSettings.currentPage}_mobile.jpg` : `${host}/static/img/${pageSettings.currentPage}.jpg`;

		if (pageSettings.currentPage === "contents_advertising" && !isMobile) {
			coverURL = `${host}/static/img/contents_adver.jpg`;
		} else if (pageSettings.currentPage === "contact" || pageSettings.currentPage === "quality_certifications" || pageSettings.currentPage === "ethics_and_compliance" || pageSettings.currentPage === "corporate_information" || pageSettings.currentPage === "corporate_information_portugal" || pageSettings.currentPage === "legal_advisory" || pageSettings.currentPage === "cookies_policy" || pageSettings.currentPage === "error404") {
			coverURL = null;
		}

		if (coverURL) {
			$("body").append("<img class='loading-check' />");
			$(".loading-check").attr("src", coverURL).on("load", function(){
				console.log("loading check ready");
				$(".loading-check").remove();
				appSubcategory.onLoad();
			});
		} else {
			appSubcategory.onLoad();
		}


	},



	bindEvents: () => {

		$(".head_arrow_down").on("click", function(e){
			console.log("head down", $(window));
			$("body").scrollTo({left:0, top:$(window).height()},500);
		});

		$(".nav-left__link").on("click", function(e){
			e.preventDefault();
			appSubcategory.swiperH.slidePrev();
		});

		$(".nav-right__link").on("click", function(e){
			e.preventDefault();
			appSubcategory.swiperH.slideNext();
		});

	},

	onLoad: () => {
		
		$(".swiper-wrapper").css("opacity","1");
		console.log("on appSubcategory load", pageSettings.lang);
		$(".subcategory__head").removeClass("placeholder");
		$('.top_links').css("opacity","1");

		if ($(".swiper-container").length) {
			appSubcategory.initSwiper();
			appSubcategory.swiperH.unlockSwipeToNext()
			if (appSubcategory.prevSlide && appSubcategory.canGoBack)
				appSubcategory.swiperH.prependSlide(appSubcategory.prevSlide);
			if (appSubcategory.nextSlide)
				appSubcategory.swiperH.appendSlide(appSubcategory.nextSlide);
		}
		console.log("show header",isDesktop,  $(".section-cover").length);
		if (isDesktop && $(".section-cover").length) {
			$(".section-cover")
			.velocity("transition.fadeOut",
				{
					duration:1500,
					complete: function(){
						$(".section-cover").remove();
						$(".header").velocity(
							"transition.fadeIn",
							{duration: 500}
						);
					}
				}
			);
		} else {
			$(".header").velocity(
				"transition.fadeIn",
				{duration: 500}
			);
		}

	},


	initSwiper: () => {
		let prevPage, nextPage;
		const placeholderClass = !isDesktop ? "placeholder" : "placeholder-desktop";

		appSubcategory.canGoBack = !!pageSettings.prevPage.length;

		if (pageSettings.prevPage.indexOf("#")===0) {
			prevPage =  pageSettings.prevPage.substr(1);
			if (!isMobile) appSubcategory.canGoBack = false;
		} else {

			prevPage = pageSettings.prevPage;
		}

		
		if (pageSettings.nextPage.indexOf("#")===0) nextPage =  pageSettings.nextPage.substr(1);
		else nextPage = pageSettings.nextPage;


		console.log("prevPage", prevPage);
		appSubcategory.prevSlide = `<div class="swiper-slide background  ${prevPage} ${placeholderClass}">

							</div>`;

		if (nextPage) {
			appSubcategory.nextSlide = `<div class="swiper-slide background ${nextPage} ${placeholderClass}">

							</div>`;
		}

		console.log("nextPage", nextPage);
		appSubcategory.swiperH = new Swiper('.swiper-container-h.head', {
			paginationClickable: true,
			nested: true,
			spaceBetween: 0,
			followFinger:true,
			onSlideChangeEnd: onHSlideChangeEnd
		});

		appSubcategory.swiperH.lockSwipeToNext();


		function onHSlideChangeEnd(swiper){
			console.log("onHSlideChangeEnd", swiper.activeIndex);
			// document.getElementsByClassName('swiper-slide-active')[0].scrollTop=0;
			//$('.swiper-slide-active').velocity({scrollTop: 0}, 500);
			let prevPage, nextPage;
			if (swiper.activeIndex === 0) {
				const isPrevPageHome = pageSettings.prevPage.indexOf("#") === 0;
				if (isPrevPageHome) {

					const local_category = categories[pageSettings.lang][pageSettings.prevPage];
					prevPage = siteSettings.pages["index"].url + "/" + local_category;
				} else {
					prevPage = siteSettings.pages[pageSettings.prevPage].url;
				}
				window.location.href = prevPage;
			} else if (swiper.activeIndex >0) {
				const isNextPageHome = pageSettings.nextPage.indexOf("#") === 0;

				console.log("next page", pageSettings.nextPage);
				if (isNextPageHome) {

					const local_category = categories[pageSettings.lang][pageSettings.nextPage];
					nextPage = siteSettings.pages["index"].url + "/" + local_category;
				} else {
					nextPage = siteSettings.pages[pageSettings.nextPage].url;
					console.log("next page", nextPage);
				}
				window.location.href = nextPage;
			}
		}
	}

};



$(document).ready(function(){
	console.log("on ready");
	console.log(pageSettings);
	pageSettings.lang = $("body").data("lang");
	pageSettings.prevPage = $("body").data("prev-page");
	pageSettings.currentPage = $("body").data("current-page");
	pageSettings.nextPage = $("body").data("next-page");

	if (!isMobile) {
		//new WOW().init();
		console.log("wow init");
		const wow_ = new WOW({
        	live: false
    	});
		wow_.init();
		console.log('sync');
		wow_.sync();
		if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
			$('.swiper-wrapper').height(window.innerHeight + 'px');
		}	
	}

	setTimeout(function() {
	      $("#logoanim").fadeOut(1500);
	  },1000);
	  setTimeout(function() {
	  		console.log('logoshadow');
	      $("#logoshadow").fadeIn(1500);
	  },1000);


	//cookies
	const cookiesCopies = {
		"es": {
			"message": "Grup Mediapro utiliza cookies propias y de terceros para analizar el uso del sitio web. <a href='/es/politica-cookies'>Política de cookies</a>.<br> <button class='btn-normal rejectAll' onClick='rejectAll()'>RECHAZAR COOKIES</button><button class='moreInfo btn-normal' onClick='openPopUpCookies()'>CONFIGURAR</button> <button class='btn-normal rejectAll' onClick='acceptAll()'>ACEPTAR COOKIES</button>",
			"agree": "ACEPTAR"
		},
		"ca": {
			"message": "Grup Mediapro Utilitza cookies pròpies i de tercers per a analitzar l'ús del lloc web. <a href='/ca/politica-cookies'>Política de cookies</a>. <br> <button class='btn-normal rejectAll' onClick='rejectAll()'>REBUTJAR COOKIES</button><button class='moreInfo btn-normal' onClick='openPopUpCookies()'>CONFIGURAR</button> <button class='btn-normal rejectAll' onClick='acceptAll()'>ACCEPTAR COOKIES</button>",
			"agree": "ACCEPTAR"
		},
		"en": {
			"message": "Grup Mediapro uses its own and third-party cookies to analyse the use of the website. <a href='/en/cookies-policy'>Cookies policy</a>. <br><button class='btn-normal rejectAll' onClick='rejectAll()'>REJECT COOKIES</button> <button class='moreInfo btn-normal' onClick='openPopUpCookies()'>SETTINGS</button> <button class='btn-normal rejectAll' onClick='acceptAll()'>ACCEPT COOKIES</button>",
			"agree": "ACCEPT"
		}

	}

	const hideCookies = function(){
		$(".cookies-alert").hide();
	};

	const confirmCookies = function(){
		console.log("confirmCookies");
		localStorage.setItem('mediapro-cookies',true);
		//$(".cookies-alert").hide();
		$(".cookies-alert").animate({
			top: "-=100"
		}, 500, function(){
			$(".cookies-alert").fadeOut();
		});
	};

	const cookies = !!localStorage.getItem('mediapro-cookies');
	if (!cookies) {
		const cookiesAlert = `<div class='cookies-alert transition'>
		<span class='cookies-alert__message'>${cookiesCopies[pageSettings.lang].message}</span>
		<div class='cookies-alert__confirmation'><img src="/static/img/icon_close.svg"></div>
		</div>`;
		$("body").append(cookiesAlert);
		$(".cookies-alert").animate({
			top: "+=100"
		}, 1000, function(){
			$(".cookies-alert__close").fadeIn();
		});

		$("body").on("click", ".cookies-alert__confirmation", confirmCookies);
		//$(".cookies-alert__confirmation").on("click", confirmCookies);

	}

	//load siteSettings
	$.get('/static/json/project-settings.json', function(data){
		console.log("project JSON loaded", data[pageSettings.lang]);
		siteSettings = data[pageSettings.lang];
		hrefLang = data;
		console.log("project JSON loaded", hrefLang);
	});


	if ( pageSettings.currentPage === "index") app = appHome;
	else app = appSubcategory;
	console.log("pageSettings", pageSettings);
	app.onReady();
});

window.addEventListener('orientationchange', function(){
	if(!$('iframe').length){
		location.reload(true);
	}
}, false);

//año dinamico
var actualYear = new Date().getFullYear();
$('.copy').html('&copy; ' + actualYear);


//pdf FlipBook and modal functions
jQuery(document).ready(function(){

	var openModal = function () {
		jQuery('.modal_overlay').fadeIn(100);
		jQuery('.modal_window').fadeIn(100);
	
	}
	
	var closeModal = function(){
		jQuery('.modal_overlay').fadeOut(100);
		jQuery('.modal_window').fadeOut(100);
	}
	
	jQuery('.showModalBtn').click( function(e){
		e.preventDefault();
		var pdfUrl = jQuery(this).attr('data-pdfurl');
		var options = {transparent: true};
		var flipBook = jQuery("#df_manual_book").flipBook(pdfUrl, options);

		openModal();
	})
	
	jQuery('.modal_overlay').click( function(){
		jQuery("#df_manual_book").html(" ");
		closeModal();
	})
	
	jQuery('.closeBtn').click( function(){
		jQuery("#df_manual_book").html(" ");
		closeModal();
	})

		//cookies
	const warningCopies = {
		"es": {
			"message": "Te informamos de que GRUP MEDIAPRO S.L.U. (anteriormente denominada MEDIAPRODUCCIÓN S.L.U.), ha actualizado la <a href='/es/aviso-legal#politicaPrivacidad' target='_blank'>política de privacidad</a>. <strong class='pp-show--more-info'>+info</strong><br /><br /><br /><div class='pp-more--info'><div>A continuación te informamos de los cambios más importantes que hemos realizado:</div><ol><li>Para que puedas recibir de una forma clara y ordenada así como consultar de manera más intuitiva la información correspondiente a quien es el Responsable del Tratamiento (GRUP MEDIAPRO S.L.U.), cómo tratamos los datos personales y, cuáles son tus derechos hemos incluido hipervínculos que conducen al correspondiente epígrafe de la Política de Privacidad.</li><li>Asimismo, para ser más transparentes, hemos añadido información sobre las finalidades para las que tratamos los datos, las bases que legitiman el tratamiento, a quién los comunicamos, cuáles son tus derechos y, en general cómo tratamos los datos personales.</li><li>Puedes consultarnos cualquier duda sobre nuestra política de privacidad dirigiéndote al correo electrónico <a href='mailto:dpd@mediapro.tv'>dpd@mediapro.tv</a>.</li></ol></div>",
			"agree": "ACEPTAR"
		},
		"ca": {
			"message": "T'informem que GRUP MEDIAPRO S.L.U. (anteriorment denominada MEDIAPRODUCCIÓ S.L.U.), ha actualitzat la <a href='/ca/avis-legal#politicaPrivacidad'>política de privacitat</a>. <strong class='pp-show--more-info'>+info</strong><br /><br /><br /><div class='pp-more--info'><div>A continuació t'informem dels canvis més importants que hem realitzat:</div><ol><li>Perquè puguis rebre d'una forma clara i ordenada així com consultar de manera més intuïtiva la informació corresponent a qui és el Responsable del Tractament (GRUP MEDIAPRO S.L.U.), com tractem les dades personals i, quins són els teus drets hem inclòs hipervincles que condueixen al corresponent epígraf de la Política de Privacitat.</li><li>Així mateix, per ser més transparents, hem afegit informació sobre les finalitats per a les quals tractem les dades, les bases que legitimen el tractament, a qui les comuniquem, quins són els teus drets i, en general com tractem les dades personals.</li><li>Pots consultar-nos qualsevol dubte sobre la nostra política de privacitat dirigint-te al correu electrònic <a href='mailto:dpd@mediapro.tv'>dpd@mediapro.tv</a>.</li></ol></div>",
			"agree": "ACCEPTAR"
		},
		"en": {
			"message": "We inform you that GRUP MEDIAPRO S.L.U. (formerly known as MEDIAPRODUCCIÓN S.L.U.), has updated the <a href='/en/legal-disclaimer#politicaPrivacidad'>privacy policy</a>. <strong class='pp-show--more-info'>+info</strong><br /><br /><br /><div class='pp-more--info'><div>Here are the most important changes we have made:</div><ol><li>In order for you to receive in a clear and orderly way, as well as consult in a more intuitive way, the information corresponding to who is the Data Controller (GRUP MEDIAPRO S.L.U.), how we process personal data and what your rights are, we have included hyperlinks that lead to the corresponding heading of the Privacy Policy.</li><li>Likewise, to be more transparent, we have added information on the purposes for which we process the data, the bases that legitimize the processing, to whom we communicate it, what your rights are and, in general, how we process personal data.</li><li>You can ask us any questions about our privacy policy by sending an email to <a href='mailto:dpd@mediapro.tv'>dpd@mediapro.tv</a>.</li></ol></div>",
			"agree": "ACCEPT"
		}

	}

	const confirmWarning = function(){
		console.log("confirmWarning");
		localStorage.setItem('private-policy-warning',true);
		$(".bottom-warning").animate({
			height: "100wh",
			bottom: "auto"
		}, 300, function(){
			$(".bottom-warning").fadeOut();
		});
	};


	const warning = !!localStorage.getItem('private-policy-warning');
	if (!warning) {
		const warningMessage = `<div class='bottom-warning transition'><div class='bottom-warning__message'>${warningCopies[pageSettings.lang].message}</div>	<div class='bottom-warning__confirmation'><img src="/static/img/icon_close.svg"></div></div>`;
		console.log(warningMessage);
		$("body").append(warningMessage);
		$(".bottom-warning").animate({
			height: "auto",
			bottom: "0"
		}, 1000, function(){
			$(".bottom-warning__confirmation").fadeIn();
		});

		$('.pp-more--info').hide();
		$("body").on("click", ".bottom-warning__confirmation", confirmWarning);
		$("body").on("click", '.pp-show--more-info', function(e) { $('.pp-more--info').toggle();});
	}

	$(function(){
		DFLIP.defaults.backgroundColor = "rgba(0,0,0,0.5)";
		DFLIP.defaults.enableDownload = false;
		DFLIP.defaults.hideControls = "print, share";
	});
	
});
